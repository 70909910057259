import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import TimerIcon from "@material-ui/icons/Timer";
import CardQuestion from "../../components/card/CardQuestion";

import ListIcon from "@material-ui/icons/List";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  menu: {
    marginRight: theme.spacing(2),
  },
}));

function convertMinutesToHHMMSS(time) {
  const totalSeconds = time * 60;
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
export default function NavBar(props) {
  const classes = useStyles();
  const { assesment } = props;

  let total = 0;
  assesment.assessment.list_question.forEach((item, index) => {
    total += item.max_score;
  });

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" className={classes.title}>
              {assesment.assessment.assessment_code} {" - "}
              {assesment.assessment.name}
            </Typography>
            <ListIcon />
            <Typography variant="h6" className={classes.menu}>
              {assesment.assessment.list_question.length} Questions
            </Typography>
            <TimerIcon />
            <Typography variant="h6" className={classes.menu}>
              Task Duration: {convertMinutesToHHMMSS(assesment.timer)}
            </Typography>
            <StarBorderIcon />
            <Typography variant="h6">Max Score {total}</Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
