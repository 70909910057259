import React, { useState, createContext } from 'react';

export const userContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(false);
    const [userProfile, setUserProfile] = useState(null);

    const value = {
        auth, setAuth,
        userProfile, setUserProfile
    };

    return (
        <userContext.Provider value={value}>
            {children}
        </userContext.Provider>
    )
}

export default AuthProvider