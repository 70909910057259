import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/core/styles";

function CommonSnackBar(props) {
  const { open, message, handleClose, position, bgColor } = props;
  const useStyles = makeStyles({
    snackbarStyleViaContentProps: {
      backgroundColor: bgColor,
      padding: "8px 24px",
      borderRadius: "10px",
    },
    message: {
      // maxWidth: "220px",
    },
    containerSnackbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    containerMessage: {
      //   maxWidth: "220px",
      fontSize: "19px",
      fontWeight: "500",
      fontFamily: "'Poppins', sansSerif",
      fontStyle: "normal",
      // lineHeight: "27px",
      // textAlign: "justify",
      padding: "0px",
      marginRight: "15px",
    },
  });
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: position.vertical,
        horizontal: position.horizontal,
      }}
      open={open}
      //   onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
        className: classes.snackbarStyleViaContentProps,
      }}
      className={{ message: classes.message }}
    >
      <SnackbarContent
        aria-describedby="message-id2"
        className={classes.snackbarStyleViaContentProps}
        message={
          <div id="message-id2" className={classes.containerSnackbar}>
            <div className={classes.containerMessage}>{message}</div>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        }
      />
    </Snackbar>
  );
}

export default CommonSnackBar;
