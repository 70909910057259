import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Loading from "../loading/Loading";

import logo from "../../assets/icons/logo.png";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    width: "100vw",
    fontFamily: "'Poppins', sansSerif",
  },
  navbar: {
    backgroundColor: "#F5F8FF",
    height: "10vh",
    padding: "10px",
    border: "1px solid #CFD6E5",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  questionTitle: {
    margin: "0px auto",
    fontWeight: "bold",
    color: "#2B2E33",
  },
  duration: {
    margin: "24px 0",
    fontWeight: "bold",
    color: "#6C7280",
  },
});

function ReviewAssess() {
  const classes = useStyles();
  const SERVER = "https://rndcompilersecure.southeastasia.cloudapp.azure.com/";
  const SERVER_RESULT =
    "http://ndsrndmain.southeastasia.cloudapp.azure.com/NawaEventAPI";

  const [loading, setLoading] = useState(false);

  const [dialogReset, setDialogReset] = useState(false);
  const [tabActive, setTabActive] = useState("instruction");
  const [statusRun, setStatusRun] = useState("default");
  const [compileStatus, setcompileStatus] = useState("");
  const [arrayTC, setArrayTC] = useState([]);
  const [assessmentScore, setAssessmentScore] = useState([]);
  const [snackbarState, setSnackbarState] = useState({
    status: false,
    message: "Warning 5 Minutes",
    bgColor: "red",
    position: { vertical: "bottom", horizontal: "right" },
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className={classes.root}>
          <div className={classes.navbar}>
            <img src={logo} alt="Coding.ID Logo" />
            <p className={classes.questionTitle}>FIB001 - Fibonacci</p>
            {/* <Countdown
              date={timeLeft}
              onComplete={completeHandler}
              renderer={rendererTime}
              daysInHours={true}
              onTick={timerTick}
            /> */}
            <p className={classes.duration}>Duration = 01:30:00</p>
          </div>
        </div>
      </>
    );
  }
}

export default ReviewAssess;
