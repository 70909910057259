import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LogoCode from "../../assets/icons/code-image.png";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";

// loading
import Lottie from "react-lottie";
import * as loadingAnimation from "./62092-computer-and-cloud.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation.default,
  speed: "1.5",
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const useStyles = makeStyles({
  containerDefault: {
    height: "70vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  containerResult: {
    fontFamily: "'JetBrains Mono', monospace",
    height: "70vh",
    overflowX: "hidden",
    overflowY: "scroll",
  },
  titleResult: {
    fontFamily: "'Poppins', sansSerif",
  },
  textResult: {
    marginRight: "5px",
    fontStyle: "normal",
    fontWeight: "500",
  },
});

const Result = (props) => {
  const classes = useStyles();
  const {
    statusRun,
    sampleInput,
    expectedOutput,
    userOutput,
    arrayTC,
    compileStatus,
  } = props;
  if (statusRun == "default") {
    return (
      <div className={classes.containerDefault}>
        <img src={LogoCode} alt="" />
        <p style={{ marginTop: "20px" }}>Run code first to check your output</p>
      </div>
    );
  } else if (statusRun == "loading") {
    return (
      <div className={classes.containerDefault}>
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          height={200}
          width={200}
        />
        {/* <span>{compileStatus}</span> */}
        <span>Running code.. please wait</span>
      </div>
    );
  } else if (statusRun == "success") {
    return (
      <div className={classes.containerResult}>
        <p className={classes.titleResult}>Sample Input</p>
        <div
          style={{
            width: "100%",
            backgroundColor: "#2D3A42",
            minHeight: "100px",
            padding: "10px 15px",
          }}
        >
          <span
            style={{ whiteSpace: "pre-line" }}
            className={classes.textResult}
          >
            {sampleInput}
          </span>
        </div>
        <p className={classes.titleResult} style={{ marginTop: "10px" }}>
          Your Output
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "#2D3A42",
            minHeight: "100px",
            padding: "10px 15px",
          }}
        >
          <span
            style={{ whiteSpace: "pre-line" }}
            className={classes.textResult}
          >
            {userOutput}
          </span>
        </div>
        <p className={classes.titleResult} style={{ marginTop: "10px" }}>
          Expected Output
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "#2D3A42",
            minHeight: "100px",
            padding: "10px 15px",
          }}
        >
          <span
            style={{ whiteSpace: "pre-line" }}
            className={classes.textResult}
          >
            {expectedOutput}
          </span>
        </div>
        <p className={classes.titleResult} style={{ marginTop: "10px" }}>
          <span style={{ margin: "0px auto" }}>Test Case</span>{" "}
          <Tooltip
            title="System will input some test case to your code, if you have a failed test case, you can check again the requirement from the question"
            placement="right-end"
            arrow
          >
            <InfoIcon />
          </Tooltip>
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "#2D3A42",
            minHeight: "100px",
            padding: "10px 15px",
          }}
        >
          {arrayTC.map((value, index) => (
            <>
              <p style={{ margin: "10px 0px" }} key={index}>
                {value == "success" ? (
                  <>
                    <span className={classes.textResult}>
                      Test Case {index + 1} : Success
                    </span>
                    <CheckCircleIcon
                      style={{
                        color: "#0F996B",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <span className={classes.textResult}>
                      Test Case {index + 1} : Failed
                    </span>
                    <CancelIcon style={{ color: "#DC573E" }} />
                  </>
                )}
              </p>
            </>
          ))}
        </div>
      </div>
    );
  }
};

export default Result;
