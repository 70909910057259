import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import { Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    height: 80,
    marginTop: 20,
    maxWidth: "100%",
    border: "solid 1px #E6EAF2",
    boxShadow: "none",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    // margin: 'auto',
    // marginLeft : 10,
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  shape: {
    backgroundColor: "#BEEBF3",
    width: 24,
    height: 24,
    margin: "auto",
  },
  shapeCircle: {
    borderRadius: "50%",
    margin: "auto",
  },
  grid: {},
  circle: {
    color: "black",
    backgroundColor: "#BEEBF3",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  btnAttempt: {
    backgroundColor: "#3DC3DD",
    margin: theme.spacing(1),
  },
}));

export default function CardQuestion(props) {
  const classes = useStyles();
  const {
    activeCard,
    questionCode,
    setActiveCard,
    assessment,
    num,
    questionTitle,
    score,
    test_case,
    code,
    uniqe,
  } = props;
  const [redirect, setRedirect] = React.useState(null);
  const [maxScore, setMaxScore] = React.useState(0);

  const redirectQuestion = (q_num) => {
    localStorage.setItem("auth", true);
    if (uniqe) {
      takeTaskStudent(q_num);
    } else {
      takeTaskApplicant(q_num);
    }
    // setRedirect({
    //   pathname: "/test",
    //   state: {
    //     dataAssessment: assessment.assessment,
    //     listQuestion: assessment.assessment.list_question,
    //     dataMember: assessment.member,
    //     timer: assessment.timer,
    //     startTest: Date.now(),
    //     review: false,
    //     uniqe: false,
    //     question_no: q_num,
    //   },
    // });
  };
  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  const takeTaskStudent = async (q_num) => {
    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/assessment/taketask/${code}/${uniqe}`
    )
      .then((res) => {
        // console.log(res);
        localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/test",
          state: {
            dataAssessment: res.data.assessment,
            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer,
            startTest: Date.now(),
            review: false,
            uniqe: uniqe,
            question_no: q_num,
          },
        });
      })
      .catch((err) => {
        //console.log(err.response.data.message);
        if (err.response.data.message == "Times up") {
          setRedirect({ pathname: "/timesup" });
        } else {
          setRedirect({ pathname: "/notverified" });
        }
      });
  };

  const takeTaskApplicant = async (q_num) => {
    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/assessment/taketask/${code}`
    )
      .then((res) => {
        // console.log(res);
        localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/test",
          state: {
            dataAssessment: res.data.assessment,
            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer,
            startTest: Date.now(),
            review: false,
            uniqe: false,
            question_no: q_num,
          },
        });
      })
      .catch((err) => {
        if (err.response.data.message == "Times up") {
          setRedirect({ pathname: "/timesup" });
        } else {
          setRedirect({ pathname: "/notverified" });
        }
      });
  };

  // let total = 0;
  // for (let i = 0; i < test_case.length; i++) {
  //   total += parseInt(test_case[i].score);

  // }
  const handleClick = (cardNum) => {
    setActiveCard(cardNum); // Set the currently active card
  };
  return (
    <div
      className={classes.root}
      onClick={() => handleClick(num)}
      style={{ cursor: "pointer" }}
    >
      {renderRedirect()}

      <Paper className={classes.paper}>
        <Grid
          className={classes.grid}
          justifyContent="center"
          alignItems="center"
          container
          spacing={1}
        >
          <Grid item>
            <Avatar className={classes.circle}>{num}</Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  {questionCode} - {questionTitle}
                </Typography>
              </Grid>
            </Grid>
            {activeCard === num && ( // Show the button only for the currently active card
              <Grid item justifyContent="center" alignItems="center">
                <Button
                  size="small"
                  variant="contained"
                  className={classes.btnAttempt}
                  onClick={() => redirectQuestion(num)}
                >
                  Attempt this question
                </Button>
              </Grid>
            )}
            <Grid item>
              <Typography variant="subtitle1">
                Score <strong>({score})</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
