import React from "react";
import NavBarGuide from "../../components/navbar/NavBarGuide";
import CardQuestion from "../../components/card/CardQuestion";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import CardQuestionGuide from "../../components/card/CardQuestionGuide";
import { Redirect } from "react-router-dom";

import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",

    // list-style: none,
    // padding: 0,
    // margin: 0,
  },
}));

const step = [
  {
    content: <div>Jumlah soal yang harus kamu kerjakan</div>,
    placement: "bottom",
    target: ".total-question",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Total Questions",
  },
  {
    content: <div>Waktu yang diberikan untuk mengerjakan soal</div>,
    placement: "bottom",
    target: ".total-timer",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Total Duration",
  },
  {
    content: <div>Score maksimal jika jawaban kamu semua benar</div>,
    placement: "bottom",
    target: ".total-score",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Total Score",
  },
  {
    content: <div>Judul soal yang akan kamu kerjakan</div>,
    placement: "right",
    target: ".soal",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Judul Soal",
  },
  {
    content: (
      <div>
        Pilih dan klik salah satu soal yang akan kamu jawab pertama kali
      </div>
    ),
    placement: "right",
    target: ".pilih-soal",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Pilih Soal",
  },
  {
    content: <div>Klik tombol ini untuk memulai mengerjakan soal</div>,
    placement: "bottom",
    target: ".attempblock",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Kerjakan Soal",
  },
];
function TourGuideInstruction() {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);

  const [run, setRun] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState(step);

  const [isLoading, setIsLoading] = React.useState(true);
  // const [data, setData] = React.useState(null);
  const [activeCard, setActiveCard] = useState(null); // Track the currently active card

  const [maxScore, setMaxScore] = React.useState(0);

  React.useEffect(() => {
    if (step == 5) {
    }
  }, [step]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      let step = index + (action === ACTIONS.PREV ? -1 : 1);

      if (step == 6) {
        setRedirect({
          pathname: "/guide",
        });
        return;
      }

      if (step == 5) {
        setActiveCard(1);
        setTimeout(() => {
          setStepIndex((prev) => step);
        }, 100);
      } else {
        setTimeout(() => {
          setStepIndex((prev) => step);
        }, 100);
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRedirect({
        pathname: "/guide",
      });
    }
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  return (
    <>
      {renderRedirect()}
      <Joyride
        callback={handleJoyrideCallback}
        isLastStep={true}
        run={run}
        steps={steps}
        continuous
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1500,
            },
          },
        }}
        styles={{
          buttonNext: { background: "#3f51b5" },
          options: { primaryColor: "#3f51b5", zIndex: 1500 },
          buttonSkip: {
            background: "#f74747",
            color: "#fff",
            borderRadius: "5px",
          },
        }}
      />
      <NavBarGuide />
      <Container className={classes.container}>
        <div
          style={{
            // top: "15px",
            width: "50%",
            height: "50%",
            position: "absolute",
            backgroundColor: "transparent",
          }}
          className="soal"
        ></div>
        <div
          style={{
            // top: "15px",
            width: "50%",
            height: "50%",
            position: "absolute",
            backgroundColor: "transparent",
          }}
          className="pilih-soal"
        ></div>
        <CardQuestionGuide
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          key={1}
          num={1}
          questionCode={"FD0923"}
          questionTitle={"Astronomy Zodiac Birthday Standard"}
          score={100}
          showButton={true}
        />
        <CardQuestionGuide
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          key={2}
          num={2}
          questionCode={"FD0213"}
          questionTitle={"Life Problem Summary"}
          score={50}
        />

        <CardQuestionGuide
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          key={3}
          num={3}
          questionCode={"FD0342"}
          questionTitle={"Return of Investment Weekly Reminder"}
          score={50}
        />
      </Container>
    </>
  );
}

export default TourGuideInstruction;
