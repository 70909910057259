import React from "react";
import Welcome from "../../assets/images/Welcome.png";
import Lottie from "react-lottie";
import * as loadingAnimation from "./23888-website-build.json";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import Axios from "axios";

import { Redirect } from "react-router-dom";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation.default,
  speed: "1.5",
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  backgroundColor: "#13335f",
};

const useStyles = makeStyles({
  containerLogin: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  container: {
    border: "1px solid #000000",
    borderRadius: "16px",
    padding: "16px",
    maxWidth: "620px",
  },
  blockContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    height: "100vh",
  },
  buttonReset: {
    color: "#234B88",
    textTransform: "none",
    fontSize: "12px",
    backgroundColor: "#FFF",
    border: "1px solid #234B88",
    borderRadius: "5px",
    padding: "7px 30px",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#234B88",
    },
  },
  line: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "lightgrey",
    paddingBottom: "16px",
  },
  boxBottom: {
    padding: "8px",
    marginTop: "24px",
  },
  buttonBottom: {
    width: "100%",
    textTransform: "none",
  },
  buttonRight: {
    width: "100%",
    color: "#fff",
    textTransform: "none",
    fontSize: "12px",
    backgroundColor: "#2761BA",
    border: "1px solid #F4991E",
    borderRadius: "5px",
    padding: "7px 30px",
    "&:hover": {
      backgroundColor: "#2761BA",
      color: "#fff",
    },
  },
});

const InstructionPage = (props) => {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    getDetailTask();
  }, []);

  const getDetailTask = async () => {
    let code = props.match.params.code;

    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/getdetailtaskapplicant/${code}`
    )
      .then((res) => {
        console.log(res);
        if (res?.data?.questions) {
          //console.log("not redirected");

          setData(res.data);

          if (res.data.taken) {
            setRedirect({ pathname: "/code=" + res?.data?.code });
          }
        } else {
          //console.log("redirected");
          setRedirect({ pathname: "/" });
        }
      })
      .catch((err) => {
        //console.log(err);
        setRedirect({ pathname: "/" });
        // if (err.response.data.message == "Times up") {
        //   setRedirect({ pathname: "/timesup" });
        // } else {
        //   setRedirect({ pathname: "/notverified" });
        // }
      })
      .finally(() => {
        setIsLoading(false);
      });

    //console.log(code);
  };

  if (isLoading) {
    return (
      <div className={classes.containerLogin}>
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          height={300}
          width={400}
        />
        <span className={classes.textAuth}>
          Oasys mu sedang di generate.. Mohon tunggu sebentar yak..
        </span>
      </div>
    );
  }

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  const taketask = () => {
    setRedirect({ pathname: "/code=" + data?.code });
  };

  return (
    <>
      {renderRedirect()}
      <div className={classes.blockContainer}>
        <Container className={classes.container}>
          <img src={Welcome} style={{ width: "100%" }} alt="welcome" />
          <h6
            style={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "19px",
              marginBottom: "28px",
            }}
          >
            Hi {data?.name}!
          </h6>
          <p style={{ textAlign: "justify" }}>
            Terima kasih telah mendaftar program kami. Test ini memiliki{" "}
            <span style={{ fontWeight: 600 }}>
              {data?.questions} soal logic test
            </span>{" "}
            dengan maksimal pengerjaan{" "}
            <span style={{ fontWeight: 600 }}>{data?.timer} menit. </span>
            Pastikan kamu mengerjakan logic test ini{" "}
            <span style={{ fontWeight: 600 }}>dengan laptop</span> dan{" "}
            <span style={{ fontWeight: 600 }}>tidak lebih dari 2x24 jam</span>{" "}
            atau kamu dianggap <span style={{ fontWeight: 600 }}>gugur</span>.
          </p>

          <p style={{ textAlign: "justify" }}>
            Lihat tutorial bagaimana menggunakan code editor (OASys) Coding.ID
            sebelum kamu mengerjakan tes ini.
          </p>

          <div className={classes.line}>
            <a
              href={`${process.env.REACT_APP_ASSESSMENT_END_POINT}guide-instruction`}
              target="__blank"
            >
              <Button
                className={classes.buttonReset}
                onClick={() => {}}
                variant="outlined"
              >
                <span style={{ margin: "0px auto" }}>OASys Tutorial</span>
              </Button>
            </a>
          </div>

          <div className={classes.boxBottom}>
            <p style={{ textAlign: "justify" }}>
              Kamu hanya bisa mengerjakan{" "}
              <span style={{ fontWeight: 600 }}>
                test ini sebanyak 1 kali saja.
              </span>{" "}
              Jadi pastikan kamu dalam keadaan senyaman mungkin untuk
              mengerjakan tes dan memiliki jaringan internet yang baik.
            </p>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <a
                  href={`${process.env.REACT_APP_WEB_END_POINT}dashboard`}
                  target="_self"
                >
                  <Button
                    className={classes.buttonBottom}
                    onClick={() => {}}
                    variant="outlined"
                  >
                    <span style={{ margin: "0px auto" }}>Batal</span>
                  </Button>
                </a>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                <Button
                  className={classes.buttonRight}
                  onClick={() => {
                    taketask();
                  }}
                >
                  <span style={{ margin: "0px auto" }}>Mulai Test</span>
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default InstructionPage;
